import {useCallback, useMemo} from 'preact/hooks';

import {Modal} from '~/components/Modal';
import {useI18n} from '~/foundation/I18n/hooks';

import {useInstallments} from '../context/InstallmentsContext';
import {usePaymentTermsMonorail} from '../monorail';

import {PrequalModalContent} from './PrequalModalContent';
import {SamplePlansModalContent} from './SamplePlansModalContent';
import {SplitPayModalContent} from './SplitPayModalContent';

export const InstallmentsModal = () => {
  const {cartPermalink, modalToken, modalVariant, modalOpen, setModalOpen} =
    useInstallments();
  const {translate} = useI18n();
  const {trackModalAction} = usePaymentTermsMonorail();

  const handleOnDismiss = useCallback(() => {
    trackModalAction({
      modalToken,
      action: 'close',
      cartPermalink,
    });
    setModalOpen(false);
  }, [cartPermalink, modalToken, setModalOpen, trackModalAction]);

  const content = useMemo(() => {
    switch (modalVariant) {
      case 'prequal':
        return <PrequalModalContent onClose={handleOnDismiss} />;
      case 'samplePlans':
        return <SamplePlansModalContent />;
      case 'splitPay':
      default:
        return <SplitPayModalContent />;
    }
  }, [handleOnDismiss, modalVariant]);

  return (
    <Modal
      variant="default"
      visible={modalOpen}
      onDismiss={handleOnDismiss}
      headerTitle={translate('paymentTerms.installmentsModal.title') as string}
    >
      <section
        data-testid="shopify-payment-terms-modal"
        className="w-[432px] max-w-full p-8 pt-0 font-system text-grayscale-d2/70"
      >
        <div hidden id="shopify-payment-terms-modal-warning-text">
          {translate('paymentTerms.installmentsModal.newWindow')}
        </div>
        {content}
      </section>
    </Modal>
  );
};
