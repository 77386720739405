import {contrast, hexToRGB, stringToRGB} from '~/utils/colors';

export const pickLogoColor = (backgroundColor: string): string => {
  const purpleBrand = [90, 49, 244];
  const whiteBrand = [255, 255, 255];

  const rgb = backgroundColor.startsWith('#')
    ? hexToRGB(backgroundColor)
    : stringToRGB(backgroundColor);

  const backgroundColorRgb = rgb.length >= 3 ? rgb : whiteBrand;

  return contrast(backgroundColorRgb, purpleBrand) >
    contrast(backgroundColorRgb, whiteBrand)
    ? 'text-purple-primary'
    : 'text-white';
};
