import {useCallback, useEffect, useMemo, useRef, useState} from 'preact/hooks';

import {AuthorizeIframe} from '~/components/AuthorizeIframe';
import {Button} from '~/components/Button';
import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useI18n} from '~/foundation/I18n/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {useAuthorizeUrl} from '~/hooks/useAuthorizeUrl';
import {useDebouncedCallback} from '~/hooks/useDebounce';
import {useDispatchEvent} from '~/hooks/useDispatchEvent';
import {useElementEventListener} from '~/hooks/useElementEventListener';
import type {SdkErrorCode} from '~/types/authorize';
import type {BuildAuthorizeUrlParams} from '~/types/authorizeUrlParams';
import type {
  AuthorizeErrorEvent,
  CompletedEvent,
  CustomFlowSideEffectEvent,
} from '~/types/event';
import type {IframeElement} from '~/types/iframe';
import {exchangeLoginCookie} from '~/utils/cookieExchange';

type PrequalButtonProps = Exclude<
  BuildAuthorizeUrlParams,
  'analyticsTraceId'
> & {
  disabled?: boolean;
  handleLoginCompleted?: () => void;
  handlePrequalFlowSideEffect?: (event: CustomFlowSideEffectEvent) => void;
  storefrontOrigin?: string;
};

export const PrequalButton = ({
  disabled,
  handleLoginCompleted,
  handlePrequalFlowSideEffect,
  storefrontOrigin,
  ...props
}: PrequalButtonProps) => {
  const {translate} = useI18n();
  const {notify} = useBugsnag();
  const {trackPageImpression, trackUserAction} = useMonorail();
  const dispatchEvent = useDispatchEvent();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const iframeRef = useRef<IframeElement | null>(null);
  const [error, setError] = useState<SdkErrorCode>();

  const elementEventListeners = useMemo(() => {
    const start = async () => {
      setError(undefined);
    };
    return {
      requestShow: start,
      start,
    };
  }, []);

  useElementEventListener(elementEventListeners);

  useEffect(() => {
    trackPageImpression({
      page: 'SIGN_IN_WITH_SHOP_BUTTON',
    });
  }, [trackPageImpression]);

  const handleComplete = useCallback(
    async ({loggedIn, shouldFinalizeLogin}: CompletedEvent) => {
      if (loggedIn && shouldFinalizeLogin) {
        await exchangeLoginCookie(storefrontOrigin, notify);
        /**
         * TODO: In the future we will publish an event to shop hub to create a user session.
         *
         * Issue: https://github.com/Shopify/shop-identity/issues/2859
         * Pull request: https://github.com/Shopify/shop-js/pull/2363
         */
      }
      handleLoginCompleted?.();
    },
    [handleLoginCompleted, notify, storefrontOrigin],
  );

  const prequalProps = {
    disableSignUp: false,
    flow: 'prequal',
    flowVersion: 'unspecified',
    hideCopy: false,
    proxy: true,
  };

  const {authorizeUrl} = useAuthorizeUrl({
    analyticsContext: 'loginWithShopPrequal',
    error,
    ...props,
    uxMode: 'iframe',
    ...prequalProps,
  });

  function onError({code, email, message}: AuthorizeErrorEvent): void {
    dispatchEvent('error', {
      code,
      email,
      message,
    });

    if (code === 'retriable_server_error') {
      if (error === code) {
        iframeRef.current?.reload();
      }
      setError(code);
    }
  }
  const handleButtonClick = useCallback(() => {
    trackUserAction({
      userAction: 'SIGN_IN_WITH_SHOP_BUTTON_CLICK',
    });
  }, [trackUserAction]);

  const debouncedButtonClick = useDebouncedCallback(
    handleButtonClick,
    150,
    true,
  );

  const handlePromptContinue = useCallback(() => {
    trackUserAction({
      userAction: 'SIGN_IN_WITH_SHOP_PROMPT_CONTINUE_CLICK',
    });

    iframeRef?.current?.close({
      dismissMethod: 'windoid_continue',
      reason: 'user_prompt_continue_clicked',
    });
  }, [trackUserAction]);

  const buttonText = useMemo(() => {
    return disabled
      ? translate('paymentTerms.buttons.unavailable')
      : translate('paymentTerms.buttons.check');
  }, [disabled, translate]);

  return (
    <>
      <Button
        className="m-auto flex h-full w-full cursor-pointer items-center justify-center rounded-xs px-11 py-2.5 text-button-large font-normal"
        disabled={disabled}
        onClick={debouncedButtonClick}
        ref={buttonRef}
      >
        <span
          className="whitespace-nowrap font-sans text-white"
          data-testid="prequal-button-text"
        >
          {buttonText}
        </span>
      </Button>
      <AuthorizeIframe
        activator={buttonRef}
        insideModal
        onComplete={handleComplete}
        onCustomFlowSideEffect={handlePrequalFlowSideEffect}
        onError={onError}
        onPromptContinue={handlePromptContinue}
        proxy
        ref={iframeRef}
        src={authorizeUrl}
        variant="default"
      />
    </>
  );
};
