export const AffirmIcon = () => {
  return (
    <svg
      aria-labelledby="shopify-payment-terms-modal-affirm"
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="39"
      fill="none"
    >
      <path
        fill="#000"
        d="M3.058 14.543c-.482 0-.724-.236-.724-.623 0-.72.812-.965 2.292-1.121 0 .962-.656 1.744-1.569 1.744zm.638-5.413c-1.058 0-2.275.495-2.936 1.017l.604 1.26c.53-.48 1.386-.892 2.159-.892.734 0 1.14.243 1.14.734 0 .33-.269.497-.777.563C1.99 12.056.5 12.575.5 14.026c0 1.15.826 1.846 2.116 1.846.92 0 1.74-.507 2.129-1.176v.99H6.46v-4.148c0-1.712-1.2-2.408-2.764-2.408zm17.224.188v6.367h1.837v-3.068c0-1.458.89-1.886 1.51-1.886.243 0 .57.07.785.23l.334-1.684a2.104 2.104 0 0 0-.822-.147c-.944 0-1.538.415-1.929 1.258v-1.07h-1.714zm12.979-.188c-.971 0-1.697.57-2.075 1.118-.35-.709-1.093-1.118-1.983-1.118-.971 0-1.643.535-1.954 1.15v-.962h-1.77v6.367h1.838v-3.277c0-1.177.621-1.742 1.201-1.742.525 0 1.007.337 1.007 1.207v3.812h1.835v-3.277c0-1.19.606-1.742 1.213-1.742.486 0 .998.35.998 1.193v3.826h1.834v-4.401c0-1.431-.971-2.154-2.144-2.154zm-17.447.187h-1.664v-.648c0-.842.485-1.083.903-1.083.462 0 .822.203.822.203l.566-1.284s-.573-.372-1.617-.372c-1.173 0-2.508.656-2.508 2.716v.468h-2.785v-.648c0-.842.485-1.083.903-1.083.238 0 .557.054.822.203l.566-1.284c-.338-.196-.88-.372-1.617-.372-1.173 0-2.508.656-2.508 2.716v.468H7.269v1.405h1.066v4.963h1.834v-4.963h2.785v4.963h1.834v-4.963h1.664V9.317zm1.095 6.367h1.832V9.317h-1.832v6.367z"
      />
      <path
        fill="#5A31F4"
        d="M28.24.434c-4.956 0-9.372 3.413-10.625 7.8h1.795C20.457 4.968 24.012 2.1 28.24 2.1c5.14 0 9.582 3.882 9.582 9.925 0 1.356-.177 2.58-.513 3.66h1.743l.017-.059c.286-1.115.431-2.326.431-3.6 0-6.74-4.95-11.59-11.26-11.59z"
      />
    </svg>
  );
};
