import type {JSX} from 'preact';
import {useMemo} from 'preact/hooks';

import {LEGAL_LINKS} from '../constants';

export const useLegalLinks = () => {
  return useMemo(
    () =>
      LEGAL_LINKS.reduce(
        (acc, {key, href, text}) => ({
          ...acc,
          [key]: (
            // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
            <a
              href={href}
              target="_blank"
              aria-describedby="shopify-payment-terms-modal-warning-text"
              rel="noreferrer"
              className="text-grayscale-d0 underline hover_text-black focus_text-black active_text-black"
            >
              {text}
            </a>
          ),
        }),
        {} as Record<(typeof LEGAL_LINKS)[number]['key'], JSX.Element>,
      ),
    [],
  );
};
