import type {CamelCase} from '~/types/utils';
import {toCamelCase} from '~/utils/casing';
import {constructCheckoutLink} from '~/utils/constructCheckoutLink';
import {isoDocument} from '~/utils/document';
import {isoWindow} from '~/utils/window';

import type {
  AvailableLoanType,
  CartShopifyMetadata,
  CheckoutShopifyMetadata,
  FinancingPlan,
  InstallmentPlan,
  LegacyShopifyMetadata,
  MinIneligibleMessageType,
  OnWarning,
  ProductShopifyMetadata,
  SamplePlan,
  ShopifyMetadata,
  Term,
  Variant,
} from '../types';
import {convertPriceToNumber, formatPrice} from '../utils/price';

import type {InstallmentsContextType} from './InstallmentsContext';

export type MetadataKey = keyof ShopifyMetadata | keyof LegacyShopifyMetadata;

// Extract the return type based on the extract keys
type ExtractedMetadata<
  TM extends ShopifyMetadata | LegacyShopifyMetadata,
  TK extends MetadataKey[],
> = {
  [key in TK[number] as CamelCase<key>]: TM[key & keyof TM];
};

interface ExtractSnakeCasedMetadataParams<
  TM extends ShopifyMetadata | LegacyShopifyMetadata,
  TK extends MetadataKey[],
> {
  extract: TK;
  meta: TM;
}

export function extractSnakeCasedMetadata<
  TM extends ShopifyMetadata | LegacyShopifyMetadata,
  TK extends MetadataKey[],
>({
  extract,
  meta,
}: ExtractSnakeCasedMetadataParams<TM, TK>): ExtractedMetadata<TM, TK> {
  const obj: Record<string, any> = {};

  extract.forEach((key) => {
    if (meta[key as keyof typeof meta]) {
      const camelizedKey = toCamelCase(key);

      obj[camelizedKey] = meta[key as keyof typeof meta];
    }
  });

  return obj as ExtractedMetadata<TM, TK>;
}

interface UpdatePDPVariantParams {
  currencyCode?: string;
  financingPlans: FinancingPlan[];
  variantId: number;
  variants: Variant[];
}

export function updatePDPVariant({
  currencyCode,
  financingPlans,
  variantId,
  variants,
}: UpdatePDPVariantParams) {
  const variant = variants.find((variant) => Number(variant.id) === variantId);

  if (!variant || !variant.full_price) {
    return {
      eligible: false,
      financingTermForBanner: null,
      fullPrice: '',
      loanTypes: [],
      pricePerTerm: '',
      variantAvailable: false,
    };
  }

  const variantPrice = getVariantFullPrice({
    variantId,
    variants,
  });

  const variantPriceNumber = convertPriceToNumber(variantPrice);

  const financingPlanForPrice = getFinancingPlanForPrice({
    financingPlans,
    price: variantPriceNumber,
  });

  return {
    eligible: variant.eligible,
    financingTermForBanner: financingPlanForPrice
      ? getFinancingTermForBanner(financingPlanForPrice)
      : null,
    fullPrice: variantPrice,
    loanTypes: getAvailableLoanTypes({
      fullPrice: variantPrice,
      financingPlans,
    }),
    pricePerTerm: calculatePDPVariantPrice({
      currencyCode,
      financingPlans,
      variantId,
      variants,
    }),
    variantAvailable: variant.available,
  };
}

type GetCartPermalinkProps = Pick<
  InstallmentsContextType,
  'cartDetails' | 'variantInfo' | 'modalToken'
>;
export const getCartPermalink = ({
  cartDetails,
  variantInfo,
  modalToken,
}: GetCartPermalinkProps) => {
  if (cartDetails?.token) {
    return cartDetails.token;
  } else {
    if (!variantInfo) return;
    return constructCheckoutLink({
      variants: variantInfo.idQuantityMapping,
      paymentOption: 'shop_pay_installments',
      source: 'installments_modal',
      sourceToken: modalToken,
    });
  }
};

interface VariantInputElement extends HTMLInputElement {}

export const getNewProductVariantId = (
  productForm: HTMLFormElement,
): number | undefined => {
  const variantIdElement = (productForm.querySelector('select[name^="id"]') ||
    productForm.querySelector('[name^="id"]')) as VariantInputElement | null;

  if (variantIdElement) {
    return Number(variantIdElement.value);
  }

  return extractVariantIdFromURL();
};

export const getMinIneligibleMessageType = (
  loanTypes?: AvailableLoanType[],
): MinIneligibleMessageType => {
  if (!loanTypes) {
    return 'split_pay';
  }

  if (
    (loanTypes.includes('interest') && !loanTypes.includes('split_pay')) ||
    loanTypes.includes('zero_percent')
  ) {
    return 'monthly';
  }

  return 'split_pay';
};

interface GetNumberOfPaymentTermsForPDPVariantProps {
  variantId: number;
  variants: Variant[];
}

export const getNumberOfPaymentTermsForPDPVariant = ({
  variantId,
  variants,
}: GetNumberOfPaymentTermsForPDPVariantProps) => {
  const variant = variants.find((variant) => Number(variant.id) === variantId);
  return variant?.number_of_payment_terms || 4;
};

export const getFinancingTermForBanner = (financingPlan: FinancingPlan) => {
  const maxInstallments = Math.max(
    ...financingPlan.terms.map((term) => term.installments_count),
  );

  return financingPlan.terms.find(
    (term) => term.installments_count === maxInstallments,
  )!;
};

interface GetFinacingTermForCartProps {
  price: string;
  financingPlans: FinancingPlan[];
}

export const getFinancingTermForCart = ({
  price,
  financingPlans,
}: GetFinacingTermForCartProps) => {
  const totalCartPrice = convertPriceToNumber(price);
  const financingPlanForPrice = getFinancingPlanForPrice({
    price: totalCartPrice,
    financingPlans,
  });

  if (!financingPlanForPrice) {
    return undefined;
  }

  return getFinancingTermForBanner(financingPlanForPrice);
};

interface GetAvailableLoanTypesProps {
  fullPrice: string;
  financingPlans: FinancingPlan[];
}

export const getAvailableLoanTypes = ({
  fullPrice,
  financingPlans,
}: GetAvailableLoanTypesProps) => {
  if (!financingPlans || financingPlans.length === 0 || !fullPrice) {
    return [];
  }

  const fullPriceNumber = convertPriceToNumber(fullPrice);
  const financingPlanForPrice = getFinancingPlanForPrice({
    price: fullPriceNumber,
    financingPlans,
  });

  if (!financingPlanForPrice) {
    return [];
  }

  const loanTypes = new Set<AvailableLoanType>();

  financingPlanForPrice.terms.forEach((term) => {
    if (term.loan_type === 'split_pay') {
      loanTypes.add('split_pay');
    } else if (term.apr === 0) {
      loanTypes.add('zero_percent');
    } else {
      loanTypes.add('interest');
    }
  });

  return Array.from(loanTypes);
};

export const getLowestLoanTypes = (financingPlans?: FinancingPlan[]) => {
  const lowestFinacingPlan = financingPlans?.[0] ?? null;

  if (!lowestFinacingPlan) {
    return [];
  }

  return lowestFinacingPlan.terms.map((term) => {
    if (term.loan_type === 'split_pay') {
      return 'split_pay';
    }
    return term.apr === 0 ? 'zero_percent' : 'interest';
  });
};

interface GetVariantFullPriceProps {
  variantId: number;
  variants: Variant[];
}

export const getVariantFullPrice = ({
  variantId,
  variants,
}: GetVariantFullPriceProps) => {
  return (
    variants.find((variant) => Number(variant.id) === variantId)?.full_price ??
    ''
  );
};

const THEME_SUBTOTAL_SELECTORS: Record<string, string> = {
  boundless: '.cart__subtotal',
  brooklyn: '.cart__subtotal',
  dawn: '.totals__subtotal-value, .sections.cart.new_subtotal',
  debut: '.cart-subtotal__price',
  express: '.cart__subtotal, .cart-drawer__subtotal-value',
  minimal: '.h5.cart__subtotal-price',
  narrative: '.cart-subtotal__price, .cart-drawer__subtotal-number',
  simple: '.cart__subtotal.h3',
  supply: '.h1.cart-subtotal--price',
  venture: '.CartSubtotal',
} as const;

export const cartSubtotalSelectorsForTheme = () => {
  const themeName = (isoWindow as any).Shopify?.theme?.name?.toLowerCase();
  const value = THEME_SUBTOTAL_SELECTORS[themeName] ?? null;

  if (!value) {
    const elementWithCartSubtotalAttribute = isoDocument.querySelector(
      '[data-cart-subtotal]',
    );

    if (!elementWithCartSubtotalAttribute) {
      // eslint-disable-next-line no-console
      console.warn?.(
        '[Shop Pay Installments] Cart price updates may not be handled automatically for this theme. To ensure the price shown in the Shop Pay Installments banner is updated correctly, follow the instructions found here: https://shopify.dev/themes/pricing-payments/installments#updating-the-banner-with-cart-total-changes',
      );
    }
  }

  return value;
};

export const getSellerIdInNumber = (sellerId: string | undefined) => {
  return sellerId ? Number.parseInt(sellerId, 10) : undefined;
};

interface CalculatePDPVariantPriceProps {
  currencyCode?: string;
  financingPlans: FinancingPlan[];
  variantId: number;
  variants: Variant[];
}
export function calculatePDPVariantPrice({
  currencyCode,
  financingPlans,
  variantId,
  variants,
}: CalculatePDPVariantPriceProps) {
  const variant = variants.find((variant) => Number(variant.id) === variantId);

  if (!variant || !variant.full_price) {
    return '';
  }

  const variantPrice = variant.full_price;
  const variantPriceNumber = convertPriceToNumber(variantPrice);
  const financingPlanForPrice = getFinancingPlanForPrice({
    price: variantPriceNumber,
    financingPlans,
  });

  if (!financingPlanForPrice) {
    return variant.price_per_term;
  }

  const termForBanner = getFinancingTermForBanner(financingPlanForPrice);
  return calculatePricePerTerm({
    currencyCode,
    price: variantPriceNumber,
    term: termForBanner,
  });
}

type GetFormattedSamplePlans = Pick<
  InstallmentsContextType,
  'installmentPlans' | 'fullPrice' | 'currencyCode'
>;
export function getFormattedSamplePlans({
  installmentPlans = [],
  fullPrice = '',
  currencyCode,
}: GetFormattedSamplePlans): SamplePlan[] {
  return installmentPlans.map(
    ({pricePerTerm, apr, numberOfPaymentTerms, loanType}) => {
      const totalPriceWithInterestNumber =
        convertPriceToNumber(pricePerTerm) * numberOfPaymentTerms;
      const priceWithoutInterestNumber = convertPriceToNumber(fullPrice);

      // Otherwise rounding being done throughout flow results in very small +ve/-ve interest values when apr is 0
      const interest = formatPrice({
        currencyCode,
        price:
          apr === 0
            ? 0
            : totalPriceWithInterestNumber - priceWithoutInterestNumber,
      });
      const totalPriceWithInterest = formatPrice({
        currencyCode,
        price:
          apr === 0 ? priceWithoutInterestNumber : totalPriceWithInterestNumber,
      });

      return {
        apr,
        interest,
        loanType,
        numberOfPaymentTerms,
        pricePerTerm,
        totalPriceWithInterest,
      };
    },
  );
}

interface GetSampleDisplayedTerms {
  isInAdaptiveRangeWithoutZeroInterest: boolean;
  terms: Term[];
}
export const getSampleDisplayedTerms = ({
  terms,
  isInAdaptiveRangeWithoutZeroInterest,
}: GetSampleDisplayedTerms) => {
  if (terms.length < 3) return terms;

  // Show split pay option and longest interest term
  if (isInAdaptiveRangeWithoutZeroInterest) {
    return [terms[0], terms[terms.length - 1]];
  }

  const termsWithoutSplitPay = terms.filter(
    (term) => term.loan_type !== 'split_pay',
  );
  if (termsWithoutSplitPay.length < 3) return termsWithoutSplitPay;

  return [
    termsWithoutSplitPay[0],
    termsWithoutSplitPay[termsWithoutSplitPay.length - 1],
  ];
};

interface CalculatePricePerTermProps {
  currencyCode?: string;
  price: number;
  term: Term;
}
export const calculatePricePerTerm = ({
  currencyCode,
  price,
  term,
}: CalculatePricePerTermProps) => {
  const interestRatePerMonth = term.apr / 1200;
  const numberOfPayments = term.installments_count;

  if (interestRatePerMonth === 0) {
    return formatPrice({
      currencyCode,
      price: price / numberOfPayments,
    });
  }

  const numerator =
    price *
    interestRatePerMonth *
    (1 + interestRatePerMonth) ** numberOfPayments;
  const denominator = (1 + interestRatePerMonth) ** numberOfPayments - 1;

  return formatPrice({
    currencyCode,
    price: numerator / denominator,
  });
};

interface GetFinancingPlanForPriceProps {
  price: number;
  financingPlans: FinancingPlan[];
}
export const getFinancingPlanForPrice = ({
  financingPlans,
  price,
}: GetFinancingPlanForPriceProps) => {
  return financingPlans.find((financingPlan) => {
    const minPrice = convertPriceToNumber(financingPlan.min_price);
    const maxPrice = convertPriceToNumber(financingPlan.max_price);
    return price >= minPrice && price <= maxPrice;
  });
};

interface BuildInstallmentsPlansProps {
  currencyCode?: string;
  financingPlans: FinancingPlan[];
  isInAdaptiveRangeWithoutZeroInterest: boolean;
  totalPrice?: string;
}
export const buildInstallmentPlans = ({
  currencyCode,
  financingPlans,
  isInAdaptiveRangeWithoutZeroInterest,
  totalPrice,
}: BuildInstallmentsPlansProps): InstallmentPlan[] => {
  if (!totalPrice) return [];
  const priceNumber = convertPriceToNumber(totalPrice);
  const financingPlanForPrice = getFinancingPlanForPrice({
    price: priceNumber,
    financingPlans,
  });

  if (!financingPlanForPrice) return [];
  const plans = getSampleDisplayedTerms({
    terms: financingPlanForPrice.terms,
    isInAdaptiveRangeWithoutZeroInterest,
  }).map((term) => ({
    pricePerTerm: calculatePricePerTerm({
      currencyCode,
      price: priceNumber,
      term,
    }),
    apr: term.apr,
    numberOfPaymentTerms: term.installments_count,
    loanType: term.loan_type,
  }));

  return plans;
};

export const extractVariantIdFromURL = (): number | undefined => {
  const params = new URL(isoWindow.location.href).searchParams;
  const variantParam = params.get('variant');

  if (!variantParam) return undefined;
  return isNaN(Number(variantParam)) ? undefined : Number(variantParam);
};

interface RequiredFieldsMap {
  product: (keyof ProductShopifyMetadata)[];
  cart: (keyof CartShopifyMetadata)[];
  checkout: (keyof CheckoutShopifyMetadata)[];
}

const requiredFieldsMap: RequiredFieldsMap = {
  product: ['variants', 'max_price', 'min_price', 'financing_plans'],
  cart: [
    'min_price',
    'max_price',
    'price_per_term',
    'eligible',
    'number_of_payment_terms',
    'full_price',
    'financing_plans',
  ],
  checkout: [
    'min_price',
    'max_price',
    'price_per_term',
    'eligible',
    'number_of_payment_terms',
    'full_price',
    'financing_plans',
  ],
};

function doExpectedFieldsExist<T extends object>(
  obj: T,
  expectedFields: (keyof T)[],
) {
  return expectedFields.every((field) => field in obj);
}

export function isShopifyMetadata(
  input: ShopifyMetadata | null,
  onWarning?: OnWarning,
): input is ShopifyMetadata {
  if (input == null) {
    return false;
  }

  if (input.type === 'cart') {
    return validateMetadata(input, onWarning);
  }

  if (input.type === 'checkout') {
    return validateMetadata(input, onWarning);
  }

  return validateProductMetadata(input, onWarning);
}

export function isLegacyShopifyMetadata(
  input: any,
  onWarning: OnWarning,
): input is LegacyShopifyMetadata {
  if (input == null) {
    return false;
  }

  // If the incoming metadata does not match expected types, but it's not null,
  // it's likely using a hardcoded implementation that is not scalable with banner changes long term.
  if (input?.type === 'cart') {
    validateLegacyCartMetadata(input, onWarning);
  } else {
    validateLegacyProductMetadata(input, onWarning);
  }

  return doExpectedFieldsExist(input, [
    'min_price',
    'max_price',
    // We should validate this attribute once it's provided by the liquid filter. See shop-js/issues/167
    // 'number_of_payment_terms' in input,
  ]);
}

function validateLegacyCartMetadata(input: any, onWarning: OnWarning) {
  const hasAllRequiredCartMetadata = doExpectedFieldsExist(input, [
    'min_price',
    'max_price',
    'price',
    'eligible',
    'number_of_payment_terms',
    'available_loan_types',
  ]);

  if (!hasAllRequiredCartMetadata) {
    onWarning('cart', JSON.stringify(input));
  }
}

function validateLegacyProductMetadata(input: any, onWarning: OnWarning) {
  const hasAllRequiredProductMetadata = doExpectedFieldsExist(input, [
    'variants',
    'max_price',
    'min_price',
    'number_of_payment_terms',
  ]);

  // Should have at least one variant, and that variant should have all required metadata
  const hasValidVariant =
    input.variants?.length > 0
      ? doExpectedFieldsExist(input.variants[0], [
          'id',
          'price',
          'eligible',
          'available_loan_types',
          'available',
        ])
      : false;

  if (!hasAllRequiredProductMetadata || !hasValidVariant) {
    onWarning('product', JSON.stringify(input));
  }
}

export function validateMetadata(
  metadata:
    | ProductShopifyMetadata
    | CartShopifyMetadata
    | CheckoutShopifyMetadata,
  onWarning?: OnWarning,
) {
  const requiredFields = requiredFieldsMap[metadata?.type];

  const hasAllRequiredMetadata = requiredFields
    ? doExpectedFieldsExist(
        metadata,
        requiredFields as (keyof typeof metadata)[],
      )
    : false;

  if (!hasAllRequiredMetadata) {
    onWarning?.(metadata?.type, JSON.stringify(metadata));
    return false;
  }

  return true;
}

function validateProductMetadata(
  input: ProductShopifyMetadata,
  onWarning?: OnWarning,
) {
  const hasAllRequiredProductMetadata = validateMetadata(input, onWarning);

  if (!hasAllRequiredProductMetadata) return false;

  // Should have at least one variant, and that variant should have all required metadata
  const hasValidVariant =
    input.variants?.length > 0
      ? doExpectedFieldsExist(input.variants[0], [
          'id',
          'price_per_term',
          'eligible',
          'full_price',
          'available',
        ])
      : false;

  if (!hasValidVariant) {
    onWarning?.('product', JSON.stringify(input));
    return false;
  }

  return true;
}
