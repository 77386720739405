export function CreditCardIcon() {
  return (
    <svg viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.364 1H2.636C1.733 1 1 1.784 1 2.75v10.5c0 .966.733 1.75 1.636 1.75h14.728c.903 0 1.636-.784 1.636-1.75V2.75C19 1.784 18.267 1 17.364 1ZM1 6h18M13 11h2"
        stroke="#0B0B0C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
