import {isoDocument} from '~/utils/document';

import type {FontFaceDto} from '../types';

const buildFontFaceDescriptor = (fontFace: Record<string, string>) => {
  const fontFaceRuleDeclarationMap = new Map<string, keyof FontFaceDescriptors>(
    [
      ['font-weight', 'weight'],
      ['font-display', 'display'],
      ['font-style', 'style'],
      ['font-stretch', 'stretch'],
      ['ascent-override', 'ascentOverride'],
      ['descent-override', 'descentOverride'],
      ['font-feature-settings', 'featureSettings'],
      ['line-gap-override', 'lineGapOverride'],
      ['unicode-range', 'unicodeRange'],
    ],
  );

  const fontFaceDescriptors = {} as Record<string, string>;
  fontFaceRuleDeclarationMap.forEach((value, key) => {
    if (fontFace[key]) {
      fontFaceDescriptors[value] = fontFace[key];
    }
  });

  return fontFaceDescriptors as FontFaceDescriptors;
};

export function extractFontFaceDeclarationForFontFamily(fontFamily: string) {
  if (!fontFamily || fontFamily.trim() === '') {
    return [];
  }

  const isFontFaceRule = (rule: CSSRule) => {
    // in Jest there is no CSSFontFaceRule class, so we should rely on another way to check the type
    return rule.constructor.name === 'CSSFontFaceRule';
  };

  try {
    const fontFaceList = [...isoDocument.styleSheets]
      /**
       * Some stylesheets are not accessible due to CORS policy, so probing each for availability
       */
      .filter((stylesheet) => Boolean(stylesheet.cssRules))
      /**
       * We are looking only into font-face at-rule
       */
      .flatMap(
        (stylesheet) =>
          [...stylesheet.cssRules].filter(isFontFaceRule) as CSSFontFaceRule[],
      )
      /**
       * Looking for specific font-face only
       */
      .filter((font) =>
        fontFamily.includes(font.style.getPropertyValue('font-family')),
      )
      /**
       * Reducing CSSFontFaceRule to correspond to the FontFaceDescriptors
       */
      .map((font: CSSFontFaceRule) => {
        const obj: Record<string, string> = {};
        for (let i = font.style.length; i--; ) {
          const key = font.style[i];
          if (key === 'src') {
            // normalize URL
            obj.src = font.style
              .getPropertyValue('src')
              .replace(/url\((["']?)([^"')]+)\1\)/gm, (_match, _quote, url) => {
                const isAbsoluteUrl = url.match(/^(https?:)?\/\//);
                if (isAbsoluteUrl) return _match;

                const baseURL = font.parentStyleSheet?.href ?? location.href;
                return `url("${new URL(url, baseURL)}")`;
              });
            continue;
          } else {
            obj[key] = font.style.getPropertyValue(key);
          }
        }

        return obj;
      });

    return fontFaceList.map(
      (fontFace) =>
        ({
          src: fontFace.src,
          fontFamily: fontFace['font-family'].replace(/["']/g, ''),
          fontFaceDescriptors: buildFontFaceDescriptor(fontFace),
        }) as FontFaceDto,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn?.('Error while extracting font-face declaration', error);
    return [];
  }
}
