export const AnimatedShopLogo = () => {
  return (
    <div className="relative inline-block">
      <svg
        className="block"
        fill="none"
        height={22}
        role="img"
        viewBox="0 0 52 58"
        width={22}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="animate-reveal stroke-white stroke-dasharray-reveal"
          d="M3 13C5 11.75 10.4968 6.92307 21.5 6.4999C34.5 5.99993 42 13 45 23C48.3 34 42.9211 48.1335 30.5 51C17.5 54 6.6 46 6 37C5.46667 29 10.5 25 14 23"
          strokeWidth={11}
        />
      </svg>
    </div>
  );
};
