import {useI18n} from '~/foundation/I18n/hooks';

import {AffirmIcon} from './AffirmIcon';
import {ShopPayIcon} from './ShopPayIcon';

export const InstallmentsModalFooter = () => {
  const {locale, translate} = useI18n();

  const affirmLogo = (
    <span className="inline-block">
      <AffirmIcon />
    </span>
  );

  const partnership = translate(
    locale === 'en'
      ? 'paymentTerms.installmentsModal.partnership'
      : 'paymentTerms.installmentsModal.partnershipDisclaimer',
    {
      affirmLogo,
    },
  );

  return (
    <footer className="flex flex-col items-center">
      <div className="mb-3 h-5 w-22 text-purple-primary">
        <ShopPayIcon />
      </div>
      <small className="text-caption font-light tracking-wider text-grayscale-d0">
        {partnership}
      </small>
    </footer>
  );
};
