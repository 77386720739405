export const LEGAL_LINKS = [
  {
    key: 'affirmCanadaHelpLink',
    href: 'https://www.affirm.ca/help',
    text: 'affirm.ca/help',
  },
  {
    key: 'lendersLink',
    href: 'https://www.affirm.com/lenders',
    text: 'affirm.com/lenders',
  },
  {
    key: 'licensesLink',
    href: 'https://www.affirm.com/licenses',
    text: 'affirm.com/licenses',
  },
];

// Currencies that don't use decimal places in their amounts
// https://docs.stripe.com/currencies#zero-decimal
export const ZERO_DECIMALS_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'HUF',
  'ISK',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'TWD',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];
