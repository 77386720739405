import {classNames} from '@shopify/css-utilities';
import {useState, useMemo, useCallback} from 'preact/hooks';

import type {TextStyleDto} from '~/features/PaymentTerms/types';
import {useI18n} from '~/foundation/I18n/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import {isoWindow} from '~/utils/window';

import {useInstallments} from '../context/InstallmentsContext';
import {usePaymentTermsMonorail} from '../monorail';
import {extractFontFaceDeclarationForFontFamily} from '../utils/banners';
import {pickLogoColor} from '../utils/colors';
import {convertPriceToNumber} from '../utils/price';

import {InstallmentsModal} from './InstallmentsModal';
import type {PrequalAmountOnReadyCallback} from './PrequalAmount';
import {PrequalAmount} from './PrequalAmount';
import {ShopPayIcon} from './ShopPayIcon';

export const ShopPayInstallmentsBanner = () => {
  const {
    dataLoaded,
    eligible,
    fullPrice,
    isLegacyBanner,
    pricePerTerm,
    minPrice,
    maxPrice,
    loanTypes,
    metaType,
    minIneligibleMessageType,
    financingTermForBanner,
    backgroundColor,
    numberOfPaymentTerms,
    isEligibleForPrequalification,
    hasZeroInterestLoanType,
    isInAdaptiveRangeWithoutZeroInterest,
    canShowSamplePlanModalContent,
    setModalOpen,
  } = useInstallments();

  const {element} = useRootProvider();

  const [showPrequalAmount, setShowPrequalAmount] = useState(false);
  const [prequalAmountFontLoaded, setPrequalAmountFontLoaded] = useState(false);
  usePaymentTermsMonorail();

  const {translate} = useI18n();

  const isEligibleForPrequalificationAmount = Boolean(
    fullPrice && eligible && convertPriceToNumber(fullPrice) >= 50,
  );

  const learnMoreWithAmount = useMemo(() => {
    return Boolean(fullPrice && convertPriceToNumber(fullPrice) < 150);
  }, [fullPrice]);

  const inferFontStyles = useMemo(() => {
    if (!showPrequalAmount) return;

    const containerElement = element?.shadowRoot?.querySelector(
      '#prequalAmountContainer',
    );

    if (!containerElement) return;

    const styles = isoWindow.getComputedStyle(containerElement);

    return {
      color: styles.color,
      fontSize: styles.fontSize,
      fontFamily: styles.fontFamily,
      letterSpacing: styles.letterSpacing,
      fontFace: extractFontFaceDeclarationForFontFamily(styles.fontFamily),
    } as TextStyleDto;
  }, [element, showPrequalAmount]);

  const shopPayLogo = useMemo(() => {
    const colorClass = pickLogoColor(backgroundColor);

    return (
      <div className={classNames('inline-flex align-middle', colorClass)}>
        <ShopPayIcon className="h-[14px] w-[59px]" />
      </div>
    );
  }, [backgroundColor]);

  const getIneligibleContent = useCallback(() => {
    let translationKey = 'paymentTerms.banner.nonEligibleMin';
    if (minIneligibleMessageType === 'monthly') {
      translationKey = 'paymentTerms.banner.nonEligibleMonthlyPaymentsMin';
    } else if (numberOfPaymentTerms === 2) {
      translationKey = 'paymentTerms.banner.nonEligibleMinOverTime';
    } else if (numberOfPaymentTerms === 1) {
      translationKey = 'paymentTerms.banner.nonEligibleMinOverTime30';
    }
    const minIneligibleMessage = translate(translationKey, {
      minPrice: (
        <span
          className="font-bold"
          dangerouslySetInnerHTML={{__html: minPrice}}
        />
      ),
      shopPayLogo,
    });
    if (!fullPrice || !maxPrice) {
      return minIneligibleMessage;
    }
    const fullPriceNumber = convertPriceToNumber(fullPrice);
    const maxPriceNumber = convertPriceToNumber(maxPrice);
    if (fullPriceNumber > maxPriceNumber) {
      return translate('paymentTerms.banner.nonEligibleMax', {
        maxPrice: (
          <span
            className="font-bold"
            dangerouslySetInnerHTML={{__html: maxPrice}}
          />
        ),
        shopPayLogo,
      });
    }

    return minIneligibleMessage;
  }, [
    minIneligibleMessageType,
    numberOfPaymentTerms,
    translate,
    minPrice,
    shopPayLogo,
    fullPrice,
    maxPrice,
  ]);

  const content = useMemo(() => {
    if (metaType === 'checkout') {
      return '';
    }

    if (!loanTypes.length) {
      return getIneligibleContent();
    }

    const price = <span className="font-bold">{pricePerTerm}</span>;
    if (financingTermForBanner && hasZeroInterestLoanType) {
      const isZeroApr = financingTermForBanner.apr === 0;
      return isZeroApr
        ? translate('paymentTerms.banner.zeroInterestEligibleZeroApr', {
            price,
            shopPayLogo,
          })
        : translate('paymentTerms.banner.zeroInterestEligible', {
            price,
            shopPayLogo,
          });
    }

    if (financingTermForBanner && isInAdaptiveRangeWithoutZeroInterest) {
      return translate('paymentTerms.banner.payIn4OrAsLowAsEligible', {
        price,
        shopPayLogo,
      });
    }

    if (loanTypes.includes('split_pay')) {
      let translationKey = 'paymentTerms.banner.splitPayEligible';
      if (numberOfPaymentTerms === 2) {
        translationKey = 'paymentTerms.banner.splitPayEligible2';
      } else if (numberOfPaymentTerms === 1) {
        translationKey = 'paymentTerms.banner.splitPayEligible30';
      }
      return translate(translationKey, {price, shopPayLogo});
    }

    const onlyInterest = loanTypes.includes('interest');
    if (onlyInterest) {
      if (isLegacyBanner) {
        return translate('paymentTerms.banner.interestOnlyEligible', {
          shopPayLogo,
        });
      }
      return translate('paymentTerms.banner.dynamicInterestOnlyEligible', {
        price,
        shopPayLogo,
      });
    }
    return getIneligibleContent();
  }, [
    metaType,
    loanTypes,
    pricePerTerm,
    financingTermForBanner,
    hasZeroInterestLoanType,
    isInAdaptiveRangeWithoutZeroInterest,
    getIneligibleContent,
    translate,
    shopPayLogo,
    numberOfPaymentTerms,
    isLegacyBanner,
  ]);

  const learnMoreButtonText = useMemo(() => {
    if (isEligibleForPrequalification) {
      return translate('paymentTerms.banner.prequal');
    } else if (eligible && canShowSamplePlanModalContent) {
      return translate('paymentTerms.banner.viewSamplePlans');
    } else {
      return translate('paymentTerms.banner.learnMore');
    }
  }, [
    isEligibleForPrequalification,
    eligible,
    canShowSamplePlanModalContent,
    translate,
  ]);

  const shopifyPrequalifiedCTA = learnMoreWithAmount
    ? translate('paymentTerms.banner.learnMore')
    : translate('paymentTerms.banner.prequalContents.prequalifiedSeePlans');

  const handlePrequalAmountReady: PrequalAmountOnReadyCallback = useCallback(
    ({shopPayInstallmentsOnboarded, fontLoaded}) => {
      if (
        !shopPayInstallmentsOnboarded ||
        !isEligibleForPrequalificationAmount
      ) {
        return;
      }

      setShowPrequalAmount(true);

      if (fontLoaded) {
        setPrequalAmountFontLoaded(true);
      }
    },
    [isEligibleForPrequalificationAmount],
  );

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  if (!dataLoaded) return null;

  const shouldRenderPrequalAmount = metaType !== 'checkout' && !isLegacyBanner;

  return (
    <>
      <p
        className={classNames(
          'm-0',
          prequalAmountFontLoaded ? '' : 'font-inter',
        )}
        id="shopify-installments"
        data-testid="shopify-installments"
      >
        <span
          className="pr-1.5"
          id="shopify-installments-content"
          data-testid="shopify-installments-content"
        >
          {content}
        </span>
        <span className="relative inline-flex overflow-hidden">
          <span
            className={classNames(
              'inline-flex items-center',
              showPrequalAmount && 'absolute translate-y-full animate-fade-out',
            )}
            id="prequalBackupCTA"
          >
            <button
              type="button"
              aria-haspopup="dialog"
              className="text-inherit m-0 cursor-pointer border-0 bg-transparent p-0 font-inherit underline"
              id="shopify-installments-cta"
              data-testid="shopify-installments-cta"
              onClick={handleModalOpen}
            >
              {learnMoreButtonText}
            </button>
          </span>

          {/* Prequal Button - Slides up to replace Learn More */}
          {shouldRenderPrequalAmount && (
            <span
              className={classNames(
                'inline-flex min-w-max items-center gap-0.5',
                showPrequalAmount
                  ? 'animate-slide-up'
                  : 'absolute translate-y-full',
              )}
              id="prequalAmountContainer"
            >
              <PrequalAmount
                containerStyles={inferFontStyles}
                onLoaded={() => setShowPrequalAmount(true)}
                onReady={handlePrequalAmountReady}
              />
              <button
                type="button"
                aria-haspopup="dialog"
                className="text-inherit m-0 cursor-pointer border-0 bg-transparent p-0 font-inherit underline"
                tabIndex={-1}
                onClick={handleModalOpen}
              >
                {shopifyPrequalifiedCTA}
              </button>
            </span>
          )}
        </span>
      </p>
      <InstallmentsModal />
    </>
  );
};
