import {isoDocument} from './document';
import {isoWindow} from './window';

export function calculateContrast(color1: string, color2: string) {
  const color1Rgb = color1.startsWith('#')
    ? hexToRGB(color1)
    : stringToRGB(color1);
  const color2Rgb = color2.startsWith('#')
    ? hexToRGB(color2)
    : stringToRGB(color2);
  return contrast(color1Rgb, color2Rgb);
}

function* climbDomTree(base: ShadowRoot | Element): Generator<Element> {
  let current: ShadowRoot | Element | null = base;

  while (current) {
    if (current.parentElement) {
      current = current.parentElement;
    } else if (current instanceof ShadowRoot) {
      current = current.host;
    } else if (current instanceof Element) {
      const root = current.getRootNode();
      if (root instanceof ShadowRoot) {
        current = root.host;
      } else {
        break;
      }
    } else {
      break;
    }

    yield current;

    if (current === isoDocument.body) {
      break;
    }
  }
}

export function contrast(rgb1: number[], rgb2: number[]): number {
  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);

  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.05);
}

export function hexToRGB(hex: string): number[] {
  let red = 0;
  let green = 0;
  let blue = 0;

  // 3 digits
  if (hex.length === 4) {
    red = Number(`0x${hex[1]}${hex[1]}`);
    green = Number(`0x${hex[2]}${hex[2]}`);
    blue = Number(`0x${hex[3]}${hex[3]}`);

    // 6 digits
  } else if (hex.length === 7) {
    red = Number(`0x${hex[1]}${hex[2]}`);
    green = Number(`0x${hex[3]}${hex[4]}`);
    blue = Number(`0x${hex[5]}${hex[6]}`);
  }

  return [red, green, blue];
}

export function inferBackgroundColor(
  mainElement: HTMLElement | null | undefined,
) {
  if (!mainElement) {
    return '#ffffff';
  }
  // --color-background is used widely in themes to set the background color
  const backgroundColor = isoWindow
    .getComputedStyle(mainElement)
    .getPropertyValue('--color-background')
    ?.trim();

  if (backgroundColor) {
    return backgroundColor;
  }

  // climb the DOM tree to detect the background color
  for (const element of climbDomTree(mainElement)) {
    const backgroundColor = isoWindow
      .getComputedStyle(element)
      .getPropertyValue('background-color');
    if (backgroundColor && backgroundColor !== 'rgba(0, 0, 0, 0)') {
      return backgroundColor;
    }
  }

  // default to white
  return '#ffffff';
}

function luminance(value: number[]): number {
  const luminancePerColor = [value[0], value[1], value[2]].map(
    function (color) {
      const colorRatio = color / 255;

      return colorRatio <= 0.03928
        ? colorRatio / 12.92
        : ((colorRatio + 0.055) / 1.055) ** 2.4;
    },
  );

  return (
    luminancePerColor[0] * 0.2126 +
    luminancePerColor[1] * 0.7152 +
    luminancePerColor[2] * 0.0722
  );
}

export function stringToRGB(rgbString: string): number[] {
  const rgbValues = rgbString.match(/\d+/g) || [];
  const [red = 0, green = 0, blue = 0] = rgbValues.map((value: string) =>
    Number(value),
  );
  return [red, green, blue];
}
