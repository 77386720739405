import {useEffect} from 'preact/hooks';

import {RootProvider} from '~/foundation/RootProvider/RootProvider';
import {isoDocument} from '~/utils/document';
import register from '~/utils/register';

import {ShopPayInstallmentsBanner} from './components/ShopPayInstallmentsBanner';
import {InstallmentsProvider} from './context/InstallmentsProvider';
import type {
  ShopifyPaymentTermsProps,
  ShopifyPaymentTermsWebComponentProps,
} from './types';

const ShopifyPaymentTermsInternal = () => {
  useEffect(() => {
    // Check to make sure the fonts haven't already been loaded by another feature.
    if (
      isoDocument.querySelector(
        'style[data-description="shop-pay-installments-font-faces"]',
      )
    ) {
      return;
    }

    const style = isoDocument.createElement('style');
    style.dataset.description = 'shop-pay-installments-font-faces';
    style.appendChild(
      isoDocument.createTextNode(`
        @font-face {
          font-family: 'InterVariable';
          src: url('https://cdn.shopify.com/static/fonts/inter/v4/InterVariable.woff2') format('woff2');
          font-weight: 100 900;
          font-display: swap;
          font-style: normal;
          font-named-instance: 'Regular';
        }`),
    );
    isoDocument.head.appendChild(style);
  }, []);

  return <ShopPayInstallmentsBanner />;
};

const getFeatureDictionary = async (locale: string) =>
  // Temporary adding an await here so that the i18n-dynamic-import-replacer
  // replaces the import statement with the correct file.
  // https://github.com/Shopify/shop-identity/issues/2814
  // eslint-disable-next-line no-return-await
  await import(`./translations/${locale}.json`);

// Register the component as a custom element
register<ShopifyPaymentTermsProps>(
  ({element, ...props}) => (
    <RootProvider
      element={element}
      featureName="ShopifyPaymentTerms"
      getFeatureDictionary={getFeatureDictionary}
    >
      <InstallmentsProvider {...props}>
        <ShopifyPaymentTermsInternal {...props} />
      </InstallmentsProvider>
    </RootProvider>
  ),
  {
    name: 'shopify-payment-terms',
    props: {
      variantId: 'number',
      shopifyMeta: 'string',
    },
    shadow: 'open',
  },
);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace preact.JSX {
    interface IntrinsicElements {
      ['shopify-payment-terms']: ShopifyPaymentTermsWebComponentProps;
    }
  }
}

export function ShopifyPaymentTerms(
  props: ShopifyPaymentTermsWebComponentProps,
) {
  return <shopify-payment-terms {...props} />;
}
