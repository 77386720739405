import {ZERO_DECIMALS_CURRENCIES} from '../constants';

export const convertPriceToNumber = (price: string): number => {
  // Remove all characters except digits, dots, and commas
  const cleanPrice = price.trim().replace(/[^0-9,.]/g, '');

  // Handle European number format (e.g., "1.234,56")
  const isEuropeanFormat = cleanPrice[cleanPrice.length - 3] === ',';

  return isEuropeanFormat
    ? parseFloat(cleanPrice.replace(/\./g, '').replace(/,([^,]*)$/, '.$1'))
    : parseFloat(cleanPrice.replace(/,/g, ''));
};

interface FormatPriceProps {
  currencyCode?: string;
  price: number;
}

export const formatPrice = ({
  currencyCode = 'USD',
  price,
}: FormatPriceProps): string => {
  const fractionDigits = ZERO_DECIMALS_CURRENCIES.includes(currencyCode)
    ? 0
    : 2;

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };

  // Try to use narrowSymbol first, fall back to symbol if not supported
  try {
    options.currencyDisplay = 'narrowSymbol';
    return new Intl.NumberFormat(undefined, options).format(price);
  } catch {
    options.currencyDisplay = 'symbol';
    return new Intl.NumberFormat(undefined, options).format(price);
  }
};
