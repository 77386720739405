export function FeesIcon() {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 10.547c0 4.72-3.9 8.577-8.75 8.577S1 15.267 1 10.547s3.9-8.576 8.75-8.576 8.75 3.857 8.75 8.576Z"
        stroke="#0B0B0C"
        stroke-width="2"
      />
      <path
        d="M11.488 8.07a1 1 0 1 0 1.4-1.429l-1.4 1.428ZM10.04 6.556v1-1Zm-2.908 5.877a1 1 0 1 0-1.266 1.547l1.266-1.547Zm5.054-5.079.7-.714V6.64l-.002-.001-.003-.003-.006-.006-.017-.016a2.497 2.497 0 0 0-.21-.176 4.31 4.31 0 0 0-2.609-.881v2a2.31 2.31 0 0 1 1.45.516l.007.006-.003-.003-.002-.003c-.001 0-.002 0-.002-.002h-.001l-.001-.001.7-.715Zm-2.146-1.798c-1.916 0-3.137.48-3.846 1.279-.704.793-.695 1.68-.695 1.938h2c0-.185.01-.406.19-.61.177-.2.726-.607 2.35-.607v-2ZM5.5 8.774c0 1.24 1.314 2.773 4.54 2.773v-2c-2.686 0-2.54-1.127-2.54-.773h-2Zm4.54 2.773c1.273 0 1.687.373 1.813.525a.64.64 0 0 1 .139.296v.012l.002.01.004.048c.001.007.006.05.018.104.005.02.022.098.065.192a1.8 1.8 0 0 0 .172.26c.121.113.491.296.739.327v-2c.247.03.617.213.738.326.062.075.146.207.172.26.043.094.06.173.064.191.013.054.018.097.018.103l.003.03.001.01v-.002l-.001-.013a2.413 2.413 0 0 0-.08-.448 2.64 2.64 0 0 0-.514-.982c-.609-.734-1.667-1.249-3.352-1.249v2Zm2.952 1.774c-.277-.04-.653-.242-.76-.349a1.802 1.802 0 0 1-.139-.212c-.035-.073-.054-.134-.059-.15a.971.971 0 0 1-.03-.138L12 12.444v.003c0 .019 0 .057-.005.107a.893.893 0 0 1-.12.359c-.106.176-.466.624-1.834.624v2c1.867 0 2.985-.66 3.547-1.592a2.894 2.894 0 0 0 .402-1.697 1.371 1.371 0 0 0-.041-.216.992.992 0 0 0-.06-.15 1.8 1.8 0 0 0-.139-.213c-.106-.106-.482-.31-.758-.348v2Zm-2.951.216c-.738 0-1.46-.26-2.031-.554a6.269 6.269 0 0 1-.872-.546l-.007-.005h.001v.001h.001l-.633.774a158.25 158.25 0 0 0-.631.776l.002.002.007.006a2.646 2.646 0 0 0 .093.072c.06.044.143.106.248.178.21.143.508.332.875.52.72.372 1.768.777 2.947.777v-2Z"
        fill="#0B0B0C"
      />
      <path
        d="M9.75 4.961v11.173"
        stroke="#0B0B0C"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
