import {useCallback} from 'preact/hooks';

import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import type {Cart} from '~/types/cart';
import {AbstractShopJSError} from '~/utils/errors';
import {fetchWithRetry} from '~/utils/fetch';
import {getHostname} from '~/utils/hostname';

export const useCart = () => {
  const {notify} = useBugsnag();

  const getCart = useCallback(async () => {
    const hostname = getHostname();
    try {
      const cartResponse = await fetchWithRetry(`https://${hostname}/cart.js`);
      const cartJson = await cartResponse.json();
      return cartJson as Cart;
    } catch (error) {
      notify(
        new AbstractShopJSError(
          `Error fetching cart token: ${error}`,
          'FetchCartError',
        ),
        {
          metadata: {
            shop: {
              hostname,
            },
          },
        },
      );
      return {token: '', currency: '', items: []};
    }
  }, [notify]);

  return {getCart};
};
