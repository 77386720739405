import {createPortal} from 'preact/compat';
import {useEffect, useRef} from 'preact/hooks';

import {PortalProvider} from '~/components/PortalProvider';
import {
  CORE_AUTH_DOMAIN,
  PAY_AUTH_DOMAIN,
  PAY_AUTH_DOMAIN_ALT,
} from '~/constants/authorize';
import {useI18n} from '~/foundation/I18n/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import {useDispatchEvent} from '~/hooks/useDispatchEvent';
import {useEventListener} from '~/hooks/useEventListener';
import type {MessageEventData} from '~/types/event';
import {isoDocument} from '~/utils/document';
import {isoWindow} from '~/utils/window';

import {openPopupWindow} from '../utils/window';

import {ShopPayIcon} from './ShopPayIcon';

const overlayUrl = `${CORE_AUTH_DOMAIN}/shopify_pay/prequal_authorize?target_origin=${isoWindow.location.origin}`;

export const InstallmentsPrequalOverlay = () => {
  const {translate} = useI18n();
  const {instanceId} = useRootProvider();
  const dispatchEvent = useDispatchEvent();
  const popupWindowRef = useRef<Window | null>(null);

  useEventListener({
    allowedOrigins: [PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT],
    handler: (event: MessageEventData) => {
      switch (event.type) {
        case 'close':
          popupWindowRef.current?.close();
          dispatchEvent('overlayClose');
          break;
        case 'error':
          popupWindowRef.current?.close();
          dispatchEvent('closeOverlayAndModal');
          break;
        case 'prequal_buyer_upsert_successful':
          popupWindowRef.current?.close();
          dispatchEvent('buyerOnboardingSuccess');
          break;
      }
    },
    source: popupWindowRef,
  });

  useEffect(() => {
    const handleEscKey = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape' || evt.key === 'Esc') {
        dispatchEvent('overlayClose');
      }
    };

    isoWindow.addEventListener('keydown', handleEscKey);

    return () => {
      isoWindow.removeEventListener('keydown', handleEscKey);
    };
  }, [dispatchEvent]);

  const handleContinueButtonClick = () => {
    if (!popupWindowRef.current || popupWindowRef.current.closed) {
      popupWindowRef.current = openPopupWindow({
        url: overlayUrl,
        width: 500,
        height: 750,
      });
    }

    popupWindowRef.current?.focus();
  };

  return createPortal(
    <PortalProvider
      instanceId={instanceId}
      type="modal"
      variant="installmentsPrequalOverlay"
    >
      <div
        data-testid="prequal-buyer-form-overlay"
        className="fixed inset-0 z-max flex animate-fade-in flex-col items-center justify-center bg-gradient-to-b from-black/95 to-black/60 text-center font-sans text-body-large text-white"
      >
        <div className="h-5 w-22">
          <ShopPayIcon />
        </div>
        <div className="my-5 flex flex-col items-center justify-center gap-4">
          <p>{translate('paymentTerms.prequalOverlay.needMoreInfo')}</p>
          <p>{translate('paymentTerms.prequalOverlay.clickContinue')}</p>
        </div>
        <button
          type="button"
          data-testid="installments-prequal-overlay-continue"
          className="cursor-pointer bg-transparent text-white underline hover_text-grayscale-l2l focus_outline-none"
          onClick={handleContinueButtonClick}
        >
          {translate('paymentTerms.prequalOverlay.continue')}
        </button>
      </div>
    </PortalProvider>,
    isoDocument.body,
  );
};
