import {isoDocument} from '~/utils/document';
import {isoWindow} from '~/utils/window';

export const POPUP_CLOSE_CHECK_INTERVAL = 1000;

interface PopupParameters {
  url: string;
  width: number;
  height: number;
  windowName?: string;
  onClose?: () => void;
}

export function openPopupWindow({
  url,
  width,
  height,
  windowName,
  onClose,
}: PopupParameters): Window | null {
  const windowPosition = getWindowPosition();
  const windowSize = getWindowSize();

  const clientWidth = windowSize.width || screen.width;
  const clientHeight = windowSize.height || screen.height;

  const systemZoom = getSystemZoom();
  const left = (clientWidth - width) / 2 / systemZoom + windowPosition.left;
  const top = (clientHeight - height) / 2 / systemZoom + windowPosition.top;

  const popupWindow: Window | null = isoWindow.open(
    url,
    windowName,
    `scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`,
  );
  if (!popupWindow) return null;
  popupWindow.focus();

  if (onClose) {
    const closeCheckInterval = setInterval(() => {
      if (!popupWindow.closed) return;
      onClose();
      clearInterval(closeCheckInterval);
    }, POPUP_CLOSE_CHECK_INTERVAL);
  }

  return popupWindow;
}

export const getWindowPosition = () => {
  const left =
    isoWindow.screenLeft === undefined
      ? isoWindow.screenX
      : isoWindow.screenLeft;
  const top =
    isoWindow.screenTop === undefined ? isoWindow.screenY : isoWindow.screenTop;
  return {
    left,
    top,
  };
};

export const getWindowSize = () => {
  const width = isoWindow.innerWidth || isoDocument.documentElement.clientWidth;
  const height =
    isoWindow.innerHeight || isoDocument.documentElement.clientHeight;
  return {
    width,
    height,
  };
};

export const getSystemZoom = () => {
  return screen.width && isoWindow.screen.availWidth
    ? screen.width / isoWindow.screen.availWidth
    : 1;
};
