import {useCallback, useMemo, useState} from 'preact/hooks';

import type {
  BannerTemplateCodeSignature,
  InstallmentsBannerContent,
  InstallmentsBannerType,
  InstallmentsPrequalPageType,
  ModalType,
  ModalUserAction,
} from '~/features/PaymentTerms/types';
import {useMonorail} from '~/foundation/Monorail/hooks';

const APP_VERSION = '__buildVersionBeta';

export const usePaymentTermsMonorail = () => {
  const {
    analyticsData: {analyticsTraceId},
    produceMonorailEvent,
    getTrekkieAttributes,
  } = useMonorail();
  const [trackedEvents] = useState<Set<string>>(new Set<string>());

  interface TrackInstallmentsBannerImpressionProps {
    bannerContent: InstallmentsBannerContent;
    bannerTemplateCodeSignature: BannerTemplateCodeSignature;
    eligible: boolean;
    hasPrequalLink: boolean;
    origin: InstallmentsBannerType;
    price?: string;
    variantId?: number;
  }
  const trackInstallmentsBannerImpression = useCallback(
    async ({
      bannerContent,
      bannerTemplateCodeSignature,
      eligible,
      hasPrequalLink,
      origin,
      price,
      variantId,
    }: TrackInstallmentsBannerImpressionProps): Promise<void> => {
      const identifier = variantId ? String(variantId) : 'cart';
      if (trackedEvents.has(identifier)) {
        return;
      }

      trackedEvents.add(identifier);

      const trekkieAttributes = await getTrekkieAttributes(
        'uniqToken',
        'visitToken',
        'shopId',
        'microSessionId',
        'contentLanguage',
        'currency',
      );

      const payload = {
        ...trekkieAttributes,
        origin,
        bannerContent,
        eligible,
        bannerTemplateCodeSignature,
        price,
        shopJsVersion: APP_VERSION,
        hasPrequalLink,
        analyticsTraceId,
      };

      produceMonorailEvent({
        event: {
          schemaId: 'shop_pay_installments_banner_ui_impression/3.0',
          payload,
        },
        trekkieAttributes,
        onError: () => {
          trackedEvents.delete(identifier);
        },
      });
    },
    [
      analyticsTraceId,
      getTrekkieAttributes,
      produceMonorailEvent,
      trackedEvents,
    ],
  );

  interface TrackElementImpressionProps {
    elementType: string;
    elementName: string;
  }
  const trackElementImpression = useCallback(
    async ({elementType, elementName}: TrackElementImpressionProps) => {
      const eventId = `element-${elementType}-${elementName}`;
      if (trackedEvents.has(eventId)) {
        return;
      }

      trackedEvents.add(eventId);

      const trekkieAttributes = await getTrekkieAttributes(
        'uniqToken',
        'visitToken',
        'microSessionId',
        'microSessionCount',
        'shopId',
        'themeId',
        'themeCityHash',
        'contentLanguage',
        'referer',
      );

      const payload = {
        ...trekkieAttributes,
        elementName,
        elementType,
        shopJsVersion: APP_VERSION,
      };

      produceMonorailEvent({
        event: {
          schemaId: 'shop_js_ui_impression/1.1',
          payload,
        },
        onError: () => {
          trackedEvents.delete(eventId);
        },
        trekkieAttributes,
      });
    },
    [getTrekkieAttributes, produceMonorailEvent, trackedEvents],
  );

  interface TrackModalOpenedProps {
    cartPermalink?: string;
    eligibleSpiPlanType: ModalType;
    modalToken: string;
    origin: InstallmentsBannerType;
    price?: string;
    spiPlanDetails: string;
    variantId?: number;
  }
  const trackModalOpened = useCallback(
    async ({
      cartPermalink,
      eligibleSpiPlanType,
      modalToken,
      origin,
      price,
      spiPlanDetails,
      variantId,
    }: TrackModalOpenedProps) => {
      let modalActionIdentifier: string;
      if (origin === 'cart') {
        modalActionIdentifier = `${origin}-open`;
      } else {
        modalActionIdentifier = `${modalToken}-open`;
      }

      if (trackedEvents.has(modalActionIdentifier)) {
        return;
      }
      trackedEvents.add(modalActionIdentifier);

      const trekkieAttributes = await getTrekkieAttributes(
        'uniqToken',
        'visitToken',
        'microSessionId',
        'microSessionCount',
        'shopId',
        'currency',
      );

      const payload = {
        ...trekkieAttributes,
        origin,
        modalToken,
        eligibleSpiPlanType,
        price,
        cartPermalink,
        spiPlanDetails,
        variantId,
        shopJsVersion: APP_VERSION,
      };

      produceMonorailEvent({
        event: {
          schemaId: 'shop_pay_installments_dynamic_modal_impression/1.0',
          payload,
        },
        onError: () => {
          trackedEvents.delete(modalActionIdentifier);
        },
        trekkieAttributes,
      });
    },
    [getTrekkieAttributes, trackedEvents, produceMonorailEvent],
  );

  interface TrackModalUserActionProps {
    modalToken: string;
    action: ModalUserAction;
    cartPermalink?: string;
  }
  const trackModalAction = useCallback(
    async ({
      modalToken,
      action,
      cartPermalink,
    }: TrackModalUserActionProps): Promise<void> => {
      const modalActionIdentifier = `${modalToken}-${action}`;
      if (trackedEvents.has(modalActionIdentifier)) {
        return;
      }
      trackedEvents.add(modalActionIdentifier);

      const trekkieAttributes = await getTrekkieAttributes(
        'uniqToken',
        'visitToken',
        'microSessionId',
        'microSessionCount',
        'shopId',
      );

      const payload = {
        ...trekkieAttributes,
        modalToken,
        action,
        cartPermalink,
        shopJsVersion: APP_VERSION,
      };

      produceMonorailEvent({
        event: {
          schemaId: 'shop_pay_installments_dynamic_modal_user_actions/1.0',
          payload,
        },
        onError: () => {
          trackedEvents.delete(modalActionIdentifier);
        },
        trekkieAttributes,
      });
    },
    [getTrekkieAttributes, produceMonorailEvent, trackedEvents],
  );

  interface TrackInstallmentsPrequalPopupPageImpressionProps {
    sellerId?: number;
    pageType: InstallmentsPrequalPageType;
  }
  const trackInvalidInstallmentBannerMetadata = useCallback(
    async (
      origin: InstallmentsBannerType = 'product',
      metadata: string,
    ): Promise<void> => {
      if (trackedEvents.has('invalidBannerMetadata')) {
        return;
      }
      trackedEvents.add('invalidBannerMetadata');

      const trekkieAttributes = await getTrekkieAttributes(
        'uniqToken',
        'visitToken',
        'microSessionId',
        'microSessionCount',
        'shopId',
      );

      const payload = {
        ...trekkieAttributes,
        origin,
        metadata,
        shopJsVersion: APP_VERSION,
      };

      produceMonorailEvent({
        event: {
          schemaId: 'shop_pay_installments_banner_invalid_metadata/1.0',
          payload,
        },
        onError: () => {
          trackedEvents.delete('invalidBannerMetadata');
        },
        trekkieAttributes,
      });
    },
    [getTrekkieAttributes, produceMonorailEvent, trackedEvents],
  );

  interface TrackInstallmentsBannerPrequalInteractionProps {
    bannerContent: InstallmentsBannerContent;
    eligible: boolean;
    origin: InstallmentsBannerType;
    prequalLinkClicked: boolean;
    price: string;
  }
  const trackInstallmentsBannerPrequalInteraction = useCallback(
    async ({
      bannerContent,
      eligible,
      origin,
      prequalLinkClicked,
      price,
    }: TrackInstallmentsBannerPrequalInteractionProps): Promise<void> => {
      if (trackedEvents.has('bannerPrequalInteraction')) {
        return;
      }
      trackedEvents.add('bannerPrequalInteraction');

      const trekkieAttributes = await getTrekkieAttributes(
        'uniqToken',
        'visitToken',
        'shopId',
        'microSessionId',
        'contentLanguage',
        'currency',
      );

      const payload = {
        ...trekkieAttributes,
        origin,
        bannerContent,
        eligible,
        price,
        shopJsVersion: APP_VERSION,
        prequalLinkClicked,
        analyticsTraceId,
      };

      produceMonorailEvent({
        event: {
          schemaId: 'shop_pay_installments_banner_prequal_interaction/3.0',
          payload,
        },
        onError: () => {
          trackedEvents.delete('bannerPrequalInteraction');
        },
        trekkieAttributes,
      });
    },
    [
      analyticsTraceId,
      getTrekkieAttributes,
      produceMonorailEvent,
      trackedEvents,
    ],
  );

  const trackInstallmentsPrequalPopupPageImpression = useCallback(
    async ({
      sellerId,
      pageType,
    }: TrackInstallmentsPrequalPopupPageImpressionProps): Promise<void> => {
      if (trackedEvents.has(pageType)) {
        return;
      }
      trackedEvents.add(pageType);

      const payload = {
        analyticsTraceId,
        sellerId,
        pageType,
      };

      produceMonorailEvent({
        event: {
          schemaId: 'shop_pay_installments_prequal_popup_page_impression/3.0',
          payload,
        },
        onError: () => {
          trackedEvents.delete(pageType);
        },
        trekkieAttributes: undefined,
      });
    },
    [analyticsTraceId, trackedEvents, produceMonorailEvent],
  );

  return useMemo(
    () => ({
      trackElementImpression,
      trackInstallmentsBannerImpression,
      trackInstallmentsBannerPrequalInteraction,
      trackInvalidInstallmentBannerMetadata,
      trackInstallmentsPrequalPopupPageImpression,
      trackModalAction,
      trackModalOpened,
    }),
    [
      trackElementImpression,
      trackInstallmentsBannerImpression,
      trackInstallmentsBannerPrequalInteraction,
      trackInvalidInstallmentBannerMetadata,
      trackInstallmentsPrequalPopupPageImpression,
      trackModalAction,
      trackModalOpened,
    ],
  );
};
