import {useCallback, useEffect} from 'preact/hooks';

import {isoDocument} from '~/utils/document';

import {convertPriceToNumber} from '../../utils/price';
import {cartSubtotalSelectorsForTheme} from '../utils';

interface UseCartObserverProps {
  onPriceChange: (price: string) => void;
  numberOfPaymentTerms: number;
}

export function useCartObserver({
  onPriceChange,
  numberOfPaymentTerms,
}: UseCartObserverProps) {
  const splitCartPrice = useCallback(
    (price: number) => {
      if (isNaN(price)) {
        return;
      }
      return Math.floor((price / numberOfPaymentTerms) * 100) / 100;
    },
    [numberOfPaymentTerms],
  );

  useEffect(() => {
    const extraCartSubtotalSelectors = cartSubtotalSelectorsForTheme();

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.nodeType !== Node.ELEMENT_NODE) return;

        const mutationTarget = mutation.target as Element;
        if (
          (mutationTarget.matches('[data-cart-subtotal]') ||
            (extraCartSubtotalSelectors &&
              mutationTarget.matches(extraCartSubtotalSelectors))) &&
          mutationTarget.textContent
        ) {
          const paymentFullPrice = convertPriceToNumber(
            mutationTarget.textContent,
          );
          if (paymentFullPrice) {
            const splitPrice = splitCartPrice(paymentFullPrice);
            if (splitPrice) {
              onPriceChange(mutationTarget.textContent);
            }
          }
        }
      });
    });

    mutationObserver.observe(isoDocument as Node, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => mutationObserver.disconnect();
  }, [onPriceChange, splitCartPrice]);
}
