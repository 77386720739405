import type {AvailableLoanType, ModalType, SamplePlan} from '../types';

interface GetSplitPayModalTypeProps {
  eligible: boolean;
  loanTypes: AvailableLoanType[];
}
export const getSplitPayModalType = ({
  eligible,
  loanTypes,
}: GetSplitPayModalTypeProps): ModalType => {
  if (!loanTypes?.length || !eligible) {
    return 'ineligible';
  }

  if (loanTypes.includes('interest') && loanTypes.includes('split_pay')) {
    return 'adaptive';
  }

  if (loanTypes.includes('split_pay')) {
    return 'split_pay';
  }

  return 'interest_only';
};

export const getSamplePlansModalType = (
  samplePlans: SamplePlan[],
): ModalType => {
  const hasZeroInterestLoan = samplePlans.some(
    (plan) => plan.apr === 0 && plan.loanType === 'interest',
  );

  if (hasZeroInterestLoan) {
    const onlyZeroInterestLoans = samplePlans.every((plan) => plan.apr === 0);
    return onlyZeroInterestLoans ? 'zero_interest_only' : 'zero_interest';
  }

  // At this point, we know that no zero interest loans are present
  const isAdaptiveRange =
    samplePlans.some((plan) => plan.loanType === 'split_pay') &&
    samplePlans.some((plan) => plan.loanType === 'interest');

  return isAdaptiveRange ? 'adaptive' : 'interest_only';
};

interface GetPrequalModalTypeProps {
  eligible: boolean;
  loanTypes: AvailableLoanType[];
  samplePlans: SamplePlan[];
}
export const getPrequalModalType = ({
  eligible,
  loanTypes,
  samplePlans,
}: GetPrequalModalTypeProps): ModalType => {
  if (!loanTypes.length || !eligible) {
    return 'ineligible';
  }

  const hasZeroInterestLoan = samplePlans.some(
    (plan) => plan.apr === 0 && plan.loanType === 'interest',
  );

  if (hasZeroInterestLoan) {
    const onlyZeroInterestLoans = samplePlans.every((plan) => plan.apr === 0);
    return onlyZeroInterestLoans ? 'zero_interest_only' : 'zero_interest';
  }

  const isAdaptiveRange =
    samplePlans?.some((plan) => plan.loanType === 'split_pay') &&
    samplePlans?.some((plan) => plan.loanType === 'interest');

  return isAdaptiveRange ? 'adaptive' : 'interest_only';
};
