import {forwardRef} from 'preact/compat';
import {useCallback, useMemo} from 'preact/hooks';

import {Button} from '~/components/Button';
import {useI18n} from '~/foundation/I18n/hooks';
import {isoWindow} from '~/utils/window';

import {useInstallments} from '../context/InstallmentsContext';

export const ContinueToCheckoutButton = forwardRef<HTMLButtonElement, object>(
  (_props, ref) => {
    const {translate} = useI18n();
    const {variantInfo, checkoutUrl} = useInstallments();

    const disabled = variantInfo?.available === false;

    const onClick = useCallback(() => {
      if (checkoutUrl) {
        isoWindow.location.assign(checkoutUrl);
      }
    }, [checkoutUrl]);

    const buttonText = useMemo(() => {
      return disabled
        ? translate('paymentTerms.buttons.unavailable')
        : translate('paymentTerms.buttons.continueToCheckout');
    }, [disabled, translate]);

    return (
      <Button
        className="flex w-full justify-center rounded-xs px-4 py-2-5 text-white"
        disabled={disabled}
        onClick={onClick}
        ref={ref}
      >
        {buttonText}
      </Button>
    );
  },
);
