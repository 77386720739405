import {useI18n} from '~/foundation/I18n/hooks';

import {useInstallments} from '../context/InstallmentsContext';

export const SamplePlanList = () => {
  const {translate} = useI18n();
  const {fullPrice, samplePlans} = useInstallments();

  const subTitle = translate('paymentTerms.samplePlansContent.subtitle', {
    count: samplePlans.length,
    priceWithoutInterest: (
      <span
        className="font-bold"
        dangerouslySetInnerHTML={{__html: fullPrice}}
      />
    ),
  });

  return (
    <>
      <p
        data-testid="subtitle"
        className="mb-4 text-subtitle font-normal leading-snug text-grayscale-d0"
      >
        {subTitle}
      </p>
      <ul className="m-0 list-none rounded-xs border border-grayscale-l2 bg-grayscale-l4 px-5 py-4">
        {samplePlans.map(
          ({
            pricePerTerm,
            apr,
            numberOfPaymentTerms,
            interest,
            totalPriceWithInterest,
            loanType,
          }) => {
            const isSplitPay = loanType === 'split_pay';
            const frequency = translate(
              isSplitPay
                ? 'paymentTerms.samplePlansContent.everyTwoWeeks'
                : 'paymentTerms.samplePlansContent.monthly',
            );
            const paymentFrequency = translate(
              isSplitPay
                ? 'paymentTerms.samplePlansContent.splitPayFrequency'
                : 'paymentTerms.samplePlansContent.otherFrequency',
              {
                pricePerTerm: (
                  <span className="text-body-large font-medium">
                    {pricePerTerm}
                  </span>
                ),
                frequency,
              },
            );

            const numberOfTerms = translate(
              isSplitPay
                ? 'paymentTerms.samplePlansContent.splitPayNumberOfTerms'
                : 'paymentTerms.samplePlansContent.otherNumberOfTerms',
              {
                numberOfTerms: isSplitPay
                  ? numberOfPaymentTerms * 2
                  : numberOfPaymentTerms,
              },
            );

            return (
              <li
                key={`${pricePerTerm}-${numberOfPaymentTerms}-${loanType}`}
                className="border-b border-grayscale-l2 py-2.5 text-grayscale-d0 first_pt-0 last_border-b-0 last_pb-0"
              >
                <div className="mb-2 flex justify-between text-body-small font-normal">
                  <span
                    data-testid="list-item__frequency"
                    className="flex flex-nowrap items-end gap-1"
                  >
                    <span className="font-medium">{paymentFrequency}</span>
                    <span data-testid="list-item__number-of-terms">
                      {numberOfTerms}
                    </span>
                  </span>
                </div>
                <div className="flex flex-col space-y-2.5 text-caption font-normal">
                  <div className="flex justify-between">
                    <span data-testid="list-item__interest-detail-label">
                      {translate(
                        'paymentTerms.samplePlansContent.interestDetails',
                        {apr},
                      )}
                    </span>
                    <span data-testid="list-item__interest-amount">
                      {interest}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <>{translate('paymentTerms.samplePlansContent.total')}</>
                    <span className="font-semibold">
                      {totalPriceWithInterest}
                    </span>
                  </div>
                </div>
              </li>
            );
          },
        )}
      </ul>
    </>
  );
};
